<template>
  <div class="uploadCheckImage">
    <div class="uploadCheckImage__header">
      <el-checkbox disabled v-model="backChecked" />
      <p>
        {{ title }}
      </p>
      <span v-if="backChecked" @click="handlePreview('back')">
        {{ $t('RCD_reuploadButton') }}
      </span>
    </div>
    <div v-show="!backChecked">
      <slot />
    </div>
    <div v-show="!backChecked" class="uploadCheckImage__footer">
      <el-button type="primary" class="width-100 el-button__brand brand" @click="handlePreview()">
        {{ $t('RCD_upload_photo') }}
      </el-button>
      <input
        accept="image/*,application/pdf"
        class="hidden-file"
        @change="fileOnChange($event)"
        type="file"
        ref="fileInput">
    </div>
    <div v-if="backChecked" class="uploadCheckImage__image">
      <img v-if="file.type.startsWith('image/')" :src="url" alt="image">
      <embed
        v-else-if="file.type === 'application/pdf'"
        :src="url"
        width="300px"
        height="190px">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    value: File
  },
  data() {
    return {
      backChecked: false,
      url: '',
      file: null
    };
  },
  created() {
    if (this.value) {
      this.file = this.value;
      this.url = URL.createObjectURL(this.value);
      this.backChecked = true;
    }
  },
  methods: {
    handlePreview() {
      this.$refs.fileInput.click();
    },
    fileOnChange(e) {
      const [file] = e.target.files;
      if (file) {
        this.url = URL.createObjectURL(file);
        this.file = file;
        this.backChecked = true;
        this.$emit('fileChange', file);
      }
    }
  }
};
</script>
